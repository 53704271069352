import Vue from 'vue'
import store from '~/store'
import VueSocketIO from 'vue-socket.io'
const connection = process.env.VUE_APP_SOCKET_ENDPOINT
//console.log(process.env);
Vue.use(new VueSocketIO({
    debug: true,
    connection,
    vuex: {
        store,
        actionPrefix: 'SOCKET_',
        mutationPrefix: 'SOCKET_'
    },
    //options: { path: "/my-app/" } //Optional options
}))
